<template>
  <div class="bodyForm">
    <form
      class="addForm"
      @submit.prevent="sendData"
      method="POST"
      action="/addnews"
    >
      <h2 class="totalForm__title">Добавить новость</h2>
      <label for="images">Картинка</label>
      <input type="text" id="images" name="img" v-model="img" />
      <label for="title">Заголовок</label>
      <input type="text" id="title" name="title" v-model="title" />
      <label for="descr">Описание</label>
      <textarea
        id="descr"
        name="descr"
        v-model="descr"
        class="addForm__text"
      ></textarea>
      <button type="submit" class="radio-block__button">
        Добавить новость
      </button>
    </form>
    <div class="totalForm-wrap">
      <div class="totalForm__title">Пред просмотр</div>
      <div class="totalForm">
        <img :src="this.img" alt="" />
        <h1>{{ title }}</h1>
        <p>{{ descr }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      descr: "",
      img: "",
      prodLink: "http://server.windcrack.beget.tech/api/addNews",
      devLink: "http://localhost:5500/api/addNews",
    };
  },

  created() {
    document.title = "Добавить новость";
  },

  methods: {
    sendData() {
      const data = {
        img: this.img,
        title: this.title,
        descr: this.descr,
      };
      if (!data.img && !data.title && !data.descr) {
        return;
      }
      fetch(devLink, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          img: data.img,
          title: data.title,
          descr: data.descr,
        }),
      })
        .then((res) => res.json())
        .catch((e) => console.log(e));
      console.log("this ->", this.title, this.descr, this.img);
      this.title = this.descr = this.img = "";
    },
  },

  mounted() {
    // console.log(this.newsItems);
  },
};
</script>

<style lang="scss">
.bodyForm {
  display: flex;
  margin-top: 4rem;
}

.addForm {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;

  &__text {
    padding: 12px;
    border: 1px solid #000;
    font-size: 16px;
    margin-bottom: 12px;
    border-radius: 5px;
    max-width: 450px;
  }
}

label {
  margin-bottom: 6px;
}

input {
  padding: 12px;
  border: 1px solid #000;
  font-size: 16px;
  margin-bottom: 12px;
  border-radius: 5px;
}

.totalForm {
  display: flex;
  flex-direction: column;

  min-height: 400px;
  box-shadow: 0px 0px 20px 5px #000;
  border-radius: 5px;
  padding: 12px;
  img {
    object-fit: contain;
    //max-height: 50%;
  }

  &__title {
    font-size: 32px;
    margin: 0 0 40px;
    font-weight: 700;
  }

  &-wrap {
    max-width: 450px;
    width: 100%;
    flex-shrink: 0;
    margin: 0 auto;
  }
}

button {
  &.radio-block__button {
    width: auto;
    margin: 12px 150px;
  }

  background: transparent;
  cursor: pointer;
}
</style>